import Toast from "./Toast";
import Card from "./Card";
import { useState, useEffect } from "react";

import galleryCards from "../galleryCards.json";

export default function Gallery() {

    const [toastShown, setToastShown] = useState(false);
    const [seenToast, setSeenToast] = useState(false);

    useEffect(() => {

        // Show toast after 2 seconds, but use session storage to not show this message a second time
        setTimeout(() => {
            setToastShown(true);
            sessionStorage.setItem("seen_gallery_toast", "true");
        }, 1000);

        if(sessionStorage.getItem("seen_gallery_toast"))
            setSeenToast(true);
    }, []);

    return(
        <div className="gallery page-content">
            <div className="gallery-header mb-4">
                <h2>Gallery 😍</h2>
                <p>View all pictures we have taken together, good & bad (even though you have no bad pictures) 😍</p>
            </div>
            {toastShown && !seenToast && 
                <Toast 
                    imgSrc={"./JamisonTour.jpg"} 
                    name={"Jamison"} 
                    time={"Just now"} 
                    text={"Feel the nostalgia as you look through a gallery of our love and reminisce on all the memories we have enjoyed together."} 
                />
            }
            <div className="gallery-content d-inline-flex flex-wrap justify-content-center row-gap-4 column-gap-4">
                {galleryCards.map((card) => {
                    return <Card 
                        imgSrc={card.src}
                        title={card.title}
                        text={card.text}
                        buttonText={"View"}
                    />
                })}  
            </div>
        </div>
    );
}