import Toast from "./Toast";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import "./LoveNotes.css";

import { v4 as uuidv4 } from "uuid";

type LoveNote = {
    id: string,
    name: string,
    content: string | undefined,
    created: Date
}

export default function LoveNotes() {

    const [toastShown, setToastShown] = useState(false);
    const [seenToast, setSeenToast] = useState(false);
    const [loading, setLoading] = useState(true);
    const [notes, setNotes] = useState<Array<LoveNote>>([]);
    const [user, setUser] = useState<{ displayName: string }>({ displayName: "" });

    const messageRef = useRef<HTMLInputElement>(null);

    function createNewMessage() {
        const messageData: LoveNote = {
            id: uuidv4(),
            name: user.displayName,
            content: messageRef.current?.value,
            created: new Date()
        }
        setNotes([ ...notes, messageData]);
        if(messageRef.current !== null)
            messageRef.current.value = "";

        axios.post("https://jamisonandashlynn.com/api/love-notes", messageData).then((response) => {
            console.log(response.data);
        }).catch((error) => {
            console.error(error);
        });
    }
    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if(e.key === 'Enter') {
            e.preventDefault();
            createNewMessage();
        }
    }

    function getNoteStyling(note: LoveNote) {
        if(note.name === user.displayName)
            if(note.name === "Jamison")
                return { backgroundColor: "rgba(25, 181, 64, 0.2)" }
            else
                return { backgroundColor: "rgba(242, 31, 225, 0.2)" }
        else 
            return { backgroundColor: "whitesmoke" }
    }

    useEffect(() => {

        axios.get('https://jamisonandashlynn.com/api/love-notes').then((response) => {
            // The reason for double .data access is because response returns object with data property
            setNotes(response.data.data);
            setLoading(false);
        }).catch((error) => {
            console.error(error);
        });

        const userFound = sessionStorage.getItem("user");
        if(userFound)
            setUser(JSON.parse(userFound));

        // Show toast after 2 seconds, but use session storage to not show this message a second time
        setTimeout(() => { 
            setToastShown(true); 
            sessionStorage.setItem("seen_love_notes_toast", "true");
        }, 1000);

        if(sessionStorage.getItem("seen_love_notes_toast"))
            setSeenToast(true);
    }, []);

    return(
        <div className="love-notes page-content">
            <h2>Love Notes 💖</h2>
            <p>Send a heartfelt message as a reminder of how much we love each other :)</p>

            <form onSubmit={(e) => e.preventDefault()}>
                <div className="form shadow px-3 py-3 rounded-4">
                    <span className="text-light">Send a new message</span>
                    <div className="input-group mt-1">
                        <input required ref={messageRef} type="text" className="form-control" placeholder="Message" aria-label="Recipient's username" aria-describedby="button-addon2" onKeyDown={(e) => handleKeyDown(e)} />
                        <button className="btn btn-light" type="button" id="button-addon2" onClick={() => createNewMessage()}>Send</button>
                    </div>
                </div>
            </form>

            {loading ? <div className="spinner-border text-success my-4" role="status"> <span className="visually-hidden">Loading...</span> </div> 
            : <div className="notes py-4">
                {notes.sort((a, b) => { return (new Date(a.created)) > (new Date(b.created)) ? -1 : 1 }).map((note) => {
                    const dateObj = new Date(note.created);
                    const dateNow = new Date();
                    let renderTime = false;
                    if(dateObj.getFullYear() === dateNow.getFullYear() && dateObj.getMonth() === dateNow.getMonth() && dateObj.getDate() === dateNow.getDate())
                        renderTime = true;
                    return <div style={getNoteStyling(note)} className="note shadow-sm py-3 px-4 rounded-4 mb-3" key={note.id}>
                        <span>{note.content}</span><br/>
                        <span className="note-details"><>{note.name} @ {!renderTime ?
                            dateObj.toLocaleDateString('en-us', { day: "numeric", month: "short" })
                            : dateObj.toLocaleTimeString('en-us', { hour: "numeric", minute: "numeric" })}</>
                        </span>
                    </div>
                })}
            </div>}

            {toastShown && !seenToast && 
                <Toast 
                    imgSrc={"./JamisonTour.jpg"} 
                    name={"Jamison"} 
                    time={"Just now"} 
                    text={"Welcome to the Love Notes page. I know we can text and it has the same purpose but I figured this would be cute for us to write special words about each other and see whenever we want ❤️"} 
                />
            }
        </div>
    );
}