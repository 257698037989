type ToastProps = {
    imgSrc: string,
    name: string,
    time: string,
    text: string
}

export default function Toast({ imgSrc, name, time, text }: ToastProps) {
    return(
        <div className="position-fixed bottom-0 end-0 p-3 z-3">
            <div className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-header">
                    <img src={imgSrc} width="24" className="rounded me-2" alt="jamison's beautiful face" />
                    <strong className="me-auto">{name}</strong>
                    <small>{time}</small>
                    <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
                <div className="toast-body" dangerouslySetInnerHTML={{ __html: text }}>
                </div>
            </div>
        </div>
    );
}