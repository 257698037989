type CardProps = {
    imgSrc: string,
    title: string,
    text: string,
    buttonText: string
}

export default function Card({ imgSrc, title, text, buttonText }: CardProps) {
    return(
        <div className="card" style={{ width: "18rem" }}>
            <img src={imgSrc} className="card-img-top" alt="..." />
            <div className="card-body">
                <h5 className="card-title">{title}</h5>
                <p className="card-text">{text}</p>
            </div>
        </div>
    );
}