import Toast from "./Toast";
import { useState, useEffect } from "react";

import "./Messages.css";
import messages from "../messages.json";

export default function Messages() {

    const [toastShown, setToastShown] = useState(false);
    const [seenToast, setSeenToast] = useState(false);

    useEffect(() => {

        // Show toast after 2 seconds, but use session storage to not show this message a second time
        setTimeout(() => { 
            setToastShown(true); 
            sessionStorage.setItem("seen_messages_toast", "true");
        }, 1000);

        if(sessionStorage.getItem("seen_messages_toast"))
            setSeenToast(true);
    }, []);

    return(
        <div className="messages page-content">
            <div className="messages-header mb-4">
                <h2>Messages 💬</h2>
                <p>Enjoy the read and don't forget to smile :)</p>
            </div>
            {toastShown && !seenToast && 
                <Toast 
                    imgSrc={"./JamisonTour.jpg"} 
                    name={"Jamison"} 
                    time={"Just now"} 
                    text={"Get answers to some of your most burning questions and view my inner thoughts about you in a text message fashion :)"} 
                />
            }

            <div className="message-content">
                {messages.map((message) => {
                    return <p className={message.type} dangerouslySetInnerHTML={{ __html: message.text }}></p>
                })}
            </div>

        </div>
    );
}