import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

export default function Login() {

    const firebaseApp = initializeApp({ apiKey: "AIzaSyDEuoRiBfHytWzDqgtXkYhkf6I4JhabxBw", authDomain: "jamison-and-ashlynn.firebaseapp.com", projectId: "jamison-and-ashlynn", storageBucket: "jamison-and-ashlynn.appspot.com", messagingSenderId: "723387403525", appId: "1:723387403525:web:56d527b30f243b34e83b6c" });
    const auth = getAuth(firebaseApp);
    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();

    function handleLoginAttempt(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const email = emailRef.current?.value ? emailRef.current.value : "";
        const password = passwordRef.current?.value ? passwordRef.current.value : "";
        signInWithEmailAndPassword(auth, email, password).then((response) => {
            console.log(response);
            const user = response.user;
            sessionStorage.setItem("user", JSON.stringify(user));
            navigate("/");
        }).catch((error) => {
            console.error(error);
        })
    }

    return(
        <div className="login page-content">
            <h3 className="pb-3">Log in</h3>
            <form onSubmit={(e) => handleLoginAttempt(e)}>
                <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                    <input ref={emailRef} required type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                </div>
                <div className="mb-3">
                    <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                    <input ref={passwordRef} required type="password" className="form-control" id="exampleInputPassword1" />
                </div>
                <button type="submit" className="btn btn-success">Submit</button>
            </form>
        </div>
    );
}