import Toast from "./Toast";
import Card from "./Card";
import { useState, useEffect } from "react";

import "./Home.css";

export default function Home() {

    const [toastShown, setToastShown] = useState(false);
    const [seenToast, setSeenToast] = useState(false);
    const [tourAgain, setTourAgain] = useState(false);

    function resetToastTour() {
        sessionStorage.removeItem("seen_home_toast");
        sessionStorage.removeItem("seen_love_notes_toast");
        sessionStorage.removeItem("seen_gallery_toast");
        sessionStorage.removeItem("seen_messages_toast");
        setTourAgain(true);
        window.location.href="/";
    }

    useEffect(() => {
        

        // Show toast after 2 seconds, but use session storage to not show this message a second time
        setTimeout(() => {
            setToastShown(true);
            sessionStorage.setItem("seen_home_toast", "true");
        }, 1000);

        if(sessionStorage.getItem("seen_home_toast"))
            setSeenToast(true);
    }, []);

    return(
        <div className="home">
            <img className="cover-image" src="./coverImage.png" alt="" />

            <div className="gallery-link position-relative">
                <img style={{ filter: "brightness(50%)" }} className="w-100" src="./JamisonTour.jpg" alt="" />
                <div className="content position-absolute bottom-0 start-0 p-4">
                    <h2 className="text-light">View The Gallery</h2>
                    <p className="text-light">A collection of images throughout the years with detailed memory explanations</p>
                    <a href="/gallery"><button>Take Me There 🤩</button></a>
                </div>
            </div>

            <div className="gallery-link position-relative">
                <img style={{ filter: "brightness(50%)" }} className="w-100" src="./JamisonTour.jpg" alt="" />
                <div className="content position-absolute bottom-0 end-0 p-4 text-end">
                    <h2 className="text-light">Read The Messages</h2>
                    <p className="text-light">My thoughts and feelings of you in a text message format</p>
                    <a href="/messages"><button>Read Now 👀</button></a>
                </div>
            </div>

            <div className="gallery-link position-relative">
                <img style={{ filter: "brightness(50%)" }} className="w-100" src="./JamisonTour.jpg" alt="" />
                <div className="content position-absolute bottom-0 start-0 p-4">
                    <h2 className="text-light">Write A Love Note</h2>
                    <p className="text-light">Write a message to each other to view later and forever</p>
                    <a href="/love-notes"><button>Write One 📝</button></a>
                </div>
            </div>

            {toastShown && !seenToast && 
                <Toast 
                    imgSrc={"./JamisonTour.jpg"} 
                    name={"Jamison"} 
                    time={"Just now"} 
                    text={"Hi baby😘. Happy 3 years to the most beautiful girl in the world. Explore the rest of the website to remind you of how amazing we are together :) View the gallery <a href='/gallery'>here</a>."} 
                />
            }
        </div>
    );
}