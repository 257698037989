import React from 'react';
import logo from './logo.svg';
import './App.css';

import { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import Navbar from "./components/Navbar";
import Home from './components/Home';
import Gallery from './components/Gallery';
import Messages from './components/Messages';
import LoveNotes from './components/LoveNotes';
import Login from './components/Login';

function App() {

  const navigate = useNavigate();

  useEffect(() => {
    console.log(`Found From ENV: ${process.env.REACT_APP_FIREBASE_API_KEY}`);
    const userFound = sessionStorage.getItem("user");
    // console.log(userFound);
    if(userFound) {
      const user = JSON.parse(userFound);
      console.log(user.apiKey);
      if(user.apiKey !== process.env.REACT_APP_FIREBASE_API_KEY)
        navigate("/login");
    }    
    else {
      navigate("/login");
    }
  }, []);

  return (
    <div className="app">

      <Navbar></Navbar>

      <div className="app-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/love-notes" element={<LoveNotes />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
