import "./Navbar.css";

export default function Navbar() {

    function resetToastTour() {
        sessionStorage.removeItem("seen_home_toast");
        sessionStorage.removeItem("seen_love_notes_toast");
        sessionStorage.removeItem("seen_gallery_toast");
        sessionStorage.removeItem("seen_messages_toast");
        window.location.href="/";
    }

    return(
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
            <div className="container-fluid">
                <a className="navbar-brand" href="/"><span className="jamison">Jamison</span> and <span className="ashlynn">Ashlynn</span></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="/">Home 🏠</a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" href="/gallery">Gallery 😍</a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" href="/messages">Messages 💬</a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" href="/love-notes">Love Notes 💖</a>
                    </li>
                    <li className="nav-item" onClick={resetToastTour}>
                        <a href="/" className="nav-link">Take the tour 👍🏻</a>
                    </li>
                    <li className="nav-item" onClick={() => sessionStorage.setItem("user", "")}>
                    <a className="nav-link" href="/login">Log out ⏎</a>
                    </li>
                </ul>
                <form className="d-flex" role="search">
                    <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                    <button className="btn btn-outline-success" type="submit">Search</button>
                </form>
                </div>
            </div>
            </nav>
    );
}